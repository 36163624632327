import React from 'react';
import clsx from 'clsx';
import ReactSlider from 'react-slider';
import { createRoot } from 'react-dom/client';
import { OutlinedButton } from '../buttons/OutlinedButton';
import { FilledButton } from '../buttons/FilledButton';

interface IRedeemDialogProps {
  id?: string | number;
  initialValue: number;
  total: number;
  ratio: number;
  onConfirm: (arg: number) => void;
  onCancel: (arg: number) => void;
}

export class RedeemDialog extends React.Component<
  IRedeemDialogProps,
  { loaded: boolean; value: number }
> {
  constructor(props: IRedeemDialogProps) {
    super(props);

    this.state = {
      loaded: false,
      value: props.initialValue,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.props.onCancel(this.state.value);
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  handleYes = () => {
    this.props.onConfirm(this.state.value);
    this.handleClose();
  };

  handleNo = () => {
    this.handleClose();
  };

  render(): React.ReactNode {
    const xp = (this.props.total * this.state.value) / 100;
    const b3tr = (xp * this.props.ratio) / 100;

    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-60': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-1">
            <h1 className="text-2xl text-raisinBlack">
              You&apos;re about to convert{' '}
              <span className="font-bold">{xp.toFixed(0)}</span> xp into{' '}
              <span className="font-bold">{b3tr.toFixed(3)}</span> B3TR
            </h1>
            <p
              className={clsx([
                'mt-4 font-normal leading-normal text-raisinBlack',
              ])}
            >
              Set a value below to redeem your XP&apos;s for $B3TR.
            </p>
          </div>
          <div className="pb-12 pt-32">
            <ReactSlider
              renderTrack={(props, state) => (
                <div
                  {...props}
                  key={`track-${state.index}`}
                  className={clsx([
                    'h-[8px] rounded-lg',
                    state.index === 0 && 'rounded-e-none bg-purpleNavy',
                    state.index === 1 && 'rounded-s-none bg-beauBlue',
                  ])}
                />
              )}
              renderThumb={(props, stage) => (
                <>
                  <div
                    {...props}
                    key="thumb-0"
                    className={clsx([
                      'w-[70px] rounded-lg bg-black bg-opacity-80',
                      'text-nowrap px-4 py-2 leading-relaxed text-white',
                      'bottom-[20px] -translate-x-[24px]',
                      'flex flex-row items-center justify-center',
                    ])}
                  >
                    {stage.value} %
                  </div>
                  <div
                    {...props}
                    key="thumb-1"
                    className={clsx([
                      'size-[24px] rounded-full bg-white',
                      'bottom-[-16px] border-4 border-purpleNavy',
                    ])}
                  />
                </>
              )}
              value={this.state.value}
              onChange={(value) => this.setState({ value: value })}
            />
          </div>
          <div className="mt-16 flex flex-row items-center justify-start">
            <OutlinedButton size="large" variant="dark" onClick={this.handleNo}>
              Cancel
            </OutlinedButton>
            <FilledButton
              size="large"
              variant="dark"
              className="ml-4"
              onClick={this.handleYes}
            >
              Continue
            </FilledButton>
          </div>
        </div>
      </div>
    );
  }

  public static open(props: IRedeemDialogProps) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<RedeemDialog {...props} id={key} />);
    }
  }
}
