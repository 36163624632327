import axios from 'axios';
import { toast } from 'react-toastify';
import { store } from '../redux/store';
import { ITransactionItem } from './types';
import { authorize } from '../redux/auth.slice';
import { apiRunFingerprintjs } from './fingerprintjs';

export const apiClaimReward = (data: {
  percentage: number;
}): Promise<number> => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'answer' },
        );
        const response = await axios.post('/api/client/v1/user/claim-reward', {
          recaptcha_token: token,
          percentage: data.percentage,
        });
        store.dispatch(
          authorize({
            xp:
              ((store.getState().auth.xp ?? 0) * (100 - data.percentage)) / 100,
            reward:
              ((store.getState().auth.reward ?? 0) * (100 - data.percentage)) /
              100,
          }),
        );

        await apiRunFingerprintjs();
        resolve(response.data.data.price);
      } catch (e: any) {
        if (e.response?.data?.error === 'NOTHING_FOR_REWARD') {
          toast('You have no XP to claim');
        } else if (e.response?.data?.error === 'TOO_MANY_REQUESTS') {
          toast(
            'You are trying to claim your reward too many times. Please try again later',
          );
        } else if (e.response?.data?.error === 'SCAMMER_USER') {
          toast(
            'Your account has been flagged for suspicious activity and blocked from redeeming',
          );
        } else if (e.response?.data?.error === 'WAITING_FOR_ADMIN_SET_LIMIT') {
          toast("We're replenishing the rewards pool. Stand by.");
        } else if (e.response?.data?.error === 'EXCEED_WEEKLY_LIMIT') {
          toast("We're replenishing the rewards pool. Stand by.");
        } else {
          toast('An error occurred. Please try again later');
        }
        resolve(0);
      }
    });
  });
};

export const apiGetTransactions = async (params: {
  skip: number;
  limit: number;
}) => {
  try {
    const response = await axios.post<{
      message: string;
      data: ITransactionItem[];
    }>('/api/client/v1/user/transactions', params);
    return response.data.data;
  } catch {
    return [];
  }
};
